

.InboxEntry{
    width: 100%;
    height: 0.8in;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    padding-right: 0.5em;
    /* grid-template-columns: 2.1fr 5fr; */
}

.InboxEntry:hover{
    background-color: rgba(0, 0, 0, 0.1);
    transition-property: background-color;
    transition-duration: 0.2s;
}

.active{
    background-color: rgba(0, 0, 0, 0.1);
    transition-property: background-color;
    transition-duration: 0.2s;
}

.summaryText{
    margin: 0;
    white-space: nowrap;    
}

.summaryWrapper{
     display: flex;
     flex-direction: column;
     justify-content: center;
}
.summaryRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
}

.fakeIcon {
    width: 50%;
    padding-bottom: 50%;
    background-image: linear-gradient(
        45deg,
        hsl(240deg 100% 20%) 0%,
        hsl(260deg 73% 25%) 11%,
        hsl(269deg 53% 31%) 22%,
        hsl(278deg 39% 37%) 33%,
        hsl(289deg 29% 43%) 44%,
        hsl(304deg 22% 49%) 56%,
        hsl(319deg 27% 58%) 67%,
        hsl(333deg 36% 67%) 78%,
        hsl(347deg 52% 76%) 89%,
        hsl(0deg 89% 86%) 100%
      );
    border-radius: 100px;
}

.sender{
    font-size: 1.1em;
    font-weight: bold;
}

.blurb {
    font-weight: lighter;
}

.iconWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1em;
    margin-right: 1em;
}