


.draftConfigure{
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.modifierWrapper{
    border-left: 1px solid black;
    padding-left: 2em;
}

.attackTypeWrapper{
    padding-right: 2em;
}

.draftConfigureHeader{
    font-size: 1.5em;
    font-weight: 1000;
    color: #0078EDC3;
    margin-bottom: 0;
    margin-top: 0;
}

.sendBtn{
    font-size: 1.5em;
    padding: 0.3em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 5px;
    border: 1px solid black;
    color: black;

    transition-property: all;
    transition-duration: 0.1s;
    margin-bottom: 0.2em;

}

.sendBtn:hover{
    background-color: #0078EDC3;
    color: white;
}