

.MailStatusBar {
    grid-column: 1 / span 2;
    background-color: #0078ED;
    color: white;
    padding-left: 2em;

    display: grid;
    grid-template-columns: 3fr 3fr 3fr;

    border-bottom: 1px solid white;

    font-size: 0.8em;
}

.timer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25em;
}

.calendarIcon {
    margin-top: 3px;
}

.gameState{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-top: 1em; */
    align-items: center;
    gap: 2em;
}